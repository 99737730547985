// @flow
import React, { useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import { withPermissionHOC } from "../../../permission/components/permissionHOC/permissionHOC";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useUserData from "../../../../hooks/user/useUserData";
import axios from "axios";
import { API } from "../../../../helpers/constants";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import { getRouteUrl } from "../../../../helpers/getRouteUrl";
import { ROUTE_PAGE_HOME } from "../../../../routers/routes";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Skeleton,
	Typography,
	useMediaQuery,
	Divider,
	Grid
} from "@mui/material";
import { ROUTE_PAGE_LOANS_INDEX } from "../../routes/routes";
import { LOANS } from "../../locales/namespaces";
import DocumentsList from "../../components/lists/documentsList"
import NotesTable from "../../components/tables/notesTable";
import QuestionnaireList from "../../components/lists/questionnaireList";
import { enqueueSnackbar } from "notistack";
import { handleAxiosErrors, logoutUnauthorizedUser } from "../../../../helpers/errorHandling";
import LoansDetails from "../../components/loansDetails/loansDetails";
import useUserAction from "../../../../hooks/user/useUserAction";
import { mapLoan } from "../../types/map";
import { toCamelCase } from "../../../../helpers/helpers";
import HomeBreadcrumb from "../../../../assets/images/icons/homeBreadcrumb";
import MainCard from "../../../../theme/mantis/components/mainCard";
import ArrowDown2 from "../../../../assets/images/icons/arrowDown2";

const Details = () => {
	const { t } = useTranslation(LOANS)
	const [loading, setLoading] = useState(false)
	const { id } = useParams()
	const [loanDetails, setLoanDetails] = useState({})
	const [loansDocuments, setLoansDocuments] = useState([])
	const [status, setStatus] = useState([])
	const [loanNotes, setLoanNotes] = useState([])
	const [questionnaire, setQuestionnaire] = useState([])
	const [updateDocuments, setUpdateDocuments] = useState(false)
	const { token, isLoggedIn } = useUserData()
	const { userLogout } = useUserAction()
	const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	const handleUpdateDocuments = (updateDocuments) => setUpdateDocuments(updateDocuments)
	
	useEffect(() => {
		setLoading(true)
		axios({
			method: 'post',
			data: { LoanId: id },
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			url: `${API}/loan/details/data`,
		}).then(response => {
			const data = response.data
			const loan = data.loan
			setLoanDetails(mapLoan(loan))
			setStatus(data.status.map(status => ({
				...status,
				DescriptionEn: status.DescriptionEn /*&& toCamelCase(status.DescriptionEn)*/,
				DescriptionEl: status.DescriptionEl /*&& toCamelCase(status.DescriptionEl)*/
			})))
			setLoansDocuments(loan.Documents)
			setLoanNotes(loan.Notes)
			setQuestionnaire(loan.questionnaire)
			setLoading(false)
		}).catch(error => {
			setLoading(false)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to get loan details.')), {
					variant: 'error'
				}
			)
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}, [updateDocuments, id])
	
	return (
		<Box>
			{!matchDownSM &&
				<Breadcrumb>
					<Breadcrumb.Link iconComponent={HomeBreadcrumb} to={getRouteUrl(ROUTE_PAGE_HOME)}/>
					<Breadcrumb.Link to={getRouteUrl(ROUTE_PAGE_LOANS_INDEX)}>
						{t('Loans')}
					</Breadcrumb.Link>
					<Breadcrumb.Item>
						{t('Loan Details')}
					</Breadcrumb.Item>
				</Breadcrumb>
			}
			<Divider sx={{ mb: 1 }} light/>
			{!loading ?
				(
					<MainCard
						sx={{
							mt: 2
						}}
						title={
							<Typography variant="h4">
								{t('Loan Details')}
							</Typography>
						}
					>
						<Grid container spacing={1} sx={{ mb: 2 }}>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<LoansDetails loanDetails={loanDetails}/>
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<NotesTable loanNotes={loanNotes}/>
							</Grid>
						</Grid>
					</MainCard>
				) : (
					<React.Fragment>
						<Skeleton/>
						<Skeleton animation="wave"/>
						<Skeleton animation={false}/>
						<Skeleton/>
						<Skeleton animation="wave"/>
						<Skeleton animation={false}/>
						<Skeleton/>
						<Skeleton animation="wave"/>
						<Skeleton animation={false}/>
					</React.Fragment>
				)
			}
			<Box sx={{ mt: '32px' }}>
				{loanDetails.AccessDocs === '1' && <Accordion
					square={true} sx={{ borderRadius: '12px', mb: '32px' }}
				>
					<AccordionSummary
						expandIcon={<ArrowDown2/>}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography
							variant="h4"
							component={'div'}
							sx={{
								fontSize: '20px',
								fontStyle: 'normal',
								fontWeight: 400,
								lineHeight: 'normal'
							}}
						>
							{t('documents')}
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Grid>
							<DocumentsList
								updateDocuments={updateDocuments}
								handleUpdateDocuments={handleUpdateDocuments}
								loansDocuments={loansDocuments}
								loanStatus={loanDetails.StatusId}
								status={status}
							/>
						</Grid>
					</AccordionDetails>
				</Accordion>}
				{/*TODO::Το κρύβουμε προς το παρόν μέχρι να φτιάξουμε τα ερωτηματολόγια*/}
				{/*<Accordion
					square={true} sx={{borderRadius: '12px', mb: '32px'}}
					>
					<AccordionSummary
						expandIcon={<ArrowDown2/>}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography
							variant="h4"
							component={'div'}
							sx={{
								fontSize: '20px',
								fontStyle: 'normal',
								fontWeight: 400,
								lineHeight: 'normal'
							}}
						>
							{t('Property Questionnaire')}
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Grid>
							<QuestionnaireList questionnaire={questionnaire}/>
						</Grid>
					</AccordionDetails>
				</Accordion>*/}
			</Box>
		</Box>
	)
};

export default withPermissionHOC(Details, ['access_loan_details'])
